import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5eee2751"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "page" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_pagination = _resolveComponent("el-pagination")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_pagination, {
      background: "",
      "hide-on-single-page": true,
      layout: "total, prev, pager, next, jumper",
      total: _ctx.pages,
      "page-size": _ctx.pageSize,
      "current-page": _ctx.currentPage,
      onCurrentChange: _ctx.changePage
    }, null, 8, ["total", "page-size", "current-page", "onCurrentChange"])
  ]))
}