import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'lib-flexible/flexible'
import 'element-plus/theme-chalk/index.css'
import ElementPlus from 'element-plus'
import page from '@/components/page.vue'

const app = createApp(App)
app.component('page', page)

router.beforeEach((to, from, next) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const title = to.meta.title === undefined ? '保函申请' : to.meta.title;
  (window as any).document.title = (title as string).replace(/>/g, '-')
  if (to.meta.login) {
    if (!store.getters['session/login']) {
      next({
        path: '/error',
        query: {
          redirect: to.fullPath
        }
      })
      return
    }
  }
  next()
})
app.use(ElementPlus).use(store).use(router).mount('#app')
