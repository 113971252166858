// 用户
export default {
  namespaced: true,
  state: {
    token: '',
    authKey: '',
    code: ''
  },
  mutations: {
    setSession (state: any, value: any) {
      state.token = value.token
      state.authKey = value.authKey
      state.code = value.code
    },
    delSession (state: any) {
      state.token = ''
      state.authKey = ''
      state.code = ''
    }
  },
  actions: {
    login (state: any, value: any) {
      state.commit('setSession', value)
    }
  },
  getters: {
    getSession (state: any) {
      return {
        token: state.token,
        authKey: state.authKey,
        code: state.code
      }
    },
    login (state: any) {
      return !!state.token
    }
  }
}
