
import { defineComponent } from 'vue'
export default defineComponent({
  data () {
    return {
      // pageSize: 20
    }
  },
  props: {
    pages: Number,
    currentPage: Number,
    pageSize: Number
  },
  methods: {
    changePage (page: number) {
      this.$emit('changePage', page)
    }
  }
})
