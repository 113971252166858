import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'index',
    component: () => import('../views/index.vue'),
    meta: {
      title: '业务信息'
    }
  },
  {
    path: '/business',
    name: 'business',
    component: () => import('@/views/business.vue'),
    meta: {
      title: '工商信息'
    }
  },
  {
    path: '/business/investor',
    name: 'business_investor',
    component: () => import('@/views/business/investor.vue'),
    meta: {
      title: '投资人'
    }
  },
  {
    path: '/business/personnel',
    name: 'business_personnel',
    component: () => import('@/views/business/personnel.vue'),
    meta: {
      title: '主要人员'
    }
  },
  {
    path: '/business/branch',
    name: 'business_branch',
    component: () => import('@/views/business/branch.vue'),
    meta: {
      title: '分支机构'
    }
  },
  {
    path: '/change',
    name: 'change',
    component: () => import('../views/change.vue'),
    meta: {
      title: '变更记录'
    }
  },
  {
    path: '/bid',
    name: 'bid',
    component: () => import('../views/bid.vue'),
    meta: {
      title: '中标业绩'
    }
  },
  {
    path: '/error',
    name: 'error',
    component: () => import('../views/error.vue'),
    meta: {
      title: '拒绝访问',
      error: true
    }
  },
  {
    path: '/news',
    name: 'news',
    component: () => import('../views/news.vue'),
    meta: {
      title: '新闻公告'
    }
  },
  {
    path: '/black',
    name: 'black',
    component: () => import('../views/black.vue'),
    meta: {
      title: '黑名单'
    }
  },
  {
    path: '/risk/abnormal',
    name: 'risk_abnormal',
    component: () => import('@/views/risk/abnormal.vue'),
    meta: {
      title: '经营风险 > 经营异常'
    }
  },
  {
    path: '/risk/notice',
    name: 'risk_notice',
    component: () => import('@/views/risk/notice.vue'),
    meta: {
      title: '经营风险 > 欠税公告'
    }
  },
  {
    path: '/risk/notice/:id',
    name: 'risk_notice_info',
    component: () => import('@/views/risk/notice_info.vue'),
    meta: {
      title: '经营风险 > 欠税公告 > 公告详情'
    }
  },
  {
    path: '/law/executed',
    name: 'law_executed',
    component: () => import('@/views/law/executed.vue'),
    meta: {
      title: '法律诉讼 > 被执行标的'
    }
  },
  {
    path: '/law/executed/:id',
    name: 'law_executed_info',
    component: () => import('@/views/law/executed_info.vue'),
    meta: {
      title: '法律诉讼 > 被执行标的 > 详情'
    }
  },
  {
    path: '/law/promise',
    name: 'law_promise',
    component: () => import('@/views/law/promise.vue'),
    meta: {
      title: '法律诉讼 > 失信记录'
    }
  },
  {
    path: '/law/promise/:id',
    name: 'law_promise_info',
    component: () => import('@/views/law/promise_info.vue'),
    meta: {
      title: '法律诉讼 > 失信记录 > 详情'
    }
  },
  {
    path: '/law/bankruptcy',
    name: 'law_bankruptcy',
    component: () => import('@/views/law/bankruptcy.vue'),
    meta: {
      title: '法律诉讼 > 破产重整'
    }
  },
  {
    path: '/law/bankruptcy/:id',
    name: 'law_bankruptcy_info',
    component: () => import('@/views/law/bankruptcy_info.vue'),
    meta: {
      title: '法律诉讼 > 破产重整 > 详情'
    }
  },
  {
    path: '/law/case',
    name: 'law_case',
    component: () => import('@/views/law/case.vue'),
    meta: {
      title: '法律诉讼 > 立案信息'
    }
  },
  {
    path: '/law/case/:id',
    name: 'law_case_info',
    component: () => import('@/views/law/case_info.vue'),
    meta: {
      title: '法律诉讼 > 立案信息 > 详情'
    }
  },
  {
    path: '/law/ruling',
    name: 'law_ruling',
    component: () => import('@/views/law/ruling.vue'),
    meta: {
      title: '法律诉讼 > 裁决文书'
    }
  },
  {
    path: '/law/ruling/:id',
    name: 'law_ruling_info',
    component: () => import('@/views/law/ruling_info.vue'),
    meta: {
      title: '法律诉讼 > 裁决文书 > 详情'
    }
  },
  {
    path: '/law/high',
    name: 'law_high',
    component: () => import('@/views/law/high.vue'),
    meta: {
      title: '法律诉讼 > 限制高消费记录'
    }
  },
  {
    path: '/law/high/:id',
    name: 'law_high_info',
    component: () => import('@/views/law/high_info.vue'),
    meta: {
      title: '法律诉讼 > 限制高消费记录 > 详情'
    }
  },
  {
    path: '/law/final',
    name: 'law_final',
    component: () => import('@/views/law/final.vue'),
    meta: {
      title: '法律诉讼 > 终本案件'
    }
  },
  {
    path: '/law/final/:id',
    name: 'law_final_info',
    component: () => import('@/views/law/final_info.vue'),
    meta: {
      title: '法律诉讼 > 终本案件 > 详情'
    }
  },
  {
    path: '/law/stock',
    name: 'law_stock',
    component: () => import('@/views/law/stock.vue'),
    meta: {
      title: '法律诉讼 > 股权冻结'
    }
  },
  {
    path: '/law/stock/:id',
    name: 'law_stock_info',
    component: () => import('@/views/law/stock_info.vue'),
    meta: {
      title: '法律诉讼 > 股权冻结 > 详情'
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
