// 用户
export default {
  namespaced: true,
  state: {
    company: '',
    creditCode: ''
  },
  mutations: {
    set (state: any, value: any) {
      state.company = value.company
      state.creditCode = value.creditCode
    }
  },
  actions: {
    set (state: any, value: any) {
      state.commit('set', value)
    }
  },
  getters: {
    get (state: any) {
      return state
    }
  }
}
