
import { Options, Vue } from 'vue-class-component'
import DataHead from '@/components/head.vue'
import DataLeft from '@/components/left.vue'
import DataFoot from '@/components/foot.vue'
@Options({
  components: {
    DataHead,
    DataLeft,
    DataFoot
  }
})
export default class App extends Vue {
  mounted () {
    console.log(this.$route)
  }
}
