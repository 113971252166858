import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2b5dcafe"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "left" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_document = _resolveComponent("document")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_menu_item = _resolveComponent("el-menu-item")!
  const _component_el_sub_menu = _resolveComponent("el-sub-menu")!
  const _component_el_menu = _resolveComponent("el-menu")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_menu, {
      "default-active": _ctx.getMenuActive()
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menu, (item, index) => {
          return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
            (item.list.length)
              ? (_openBlock(), _createBlock(_component_el_sub_menu, {
                  key: 0,
                  index: index.toString()
                }, {
                  title: _withCtx(() => [
                    _createVNode(_component_el_icon, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_document)
                      ]),
                      _: 1
                    }),
                    _createElementVNode("span", null, _toDisplayString(item.name), 1)
                  ]),
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.list, (row, rowindex) => {
                      return (_openBlock(), _createBlock(_component_el_menu_item, {
                        onClick: ($event: any) => (_ctx.ckUrl(row.url)),
                        key: rowindex,
                        index: index+'-'+rowindex
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(row.name), 1)
                        ]),
                        _: 2
                      }, 1032, ["onClick", "index"]))
                    }), 128))
                  ]),
                  _: 2
                }, 1032, ["index"]))
              : (_openBlock(), _createBlock(_component_el_menu_item, {
                  key: 1,
                  index: index.toString(),
                  onClick: ($event: any) => (_ctx.ckUrl(item.url))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_icon, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_document)
                      ]),
                      _: 1
                    }),
                    _createElementVNode("span", null, _toDisplayString(item.name), 1)
                  ]),
                  _: 2
                }, 1032, ["index", "onClick"]))
          ], 64))
        }), 128))
      ]),
      _: 1
    }, 8, ["default-active"])
  ]))
}