<template>
  <div class="foot">
    深圳蔓延科技有限公司 © 2022
  </div>
</template>
<style lang="scss" scoped>
.foot{
  width: 1400px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  margin: 0 auto;
  border: 1px solid #ccc;
  background-color: #FFF;
  margin-top: 10px;
}
</style>
