
import { Options, Vue } from 'vue-class-component'
import { Document } from '@element-plus/icons'
@Options({
  components: {
    Document
  }
})
export default class Left extends Vue {
  menu = [
    {
      name: '业务信息',
      url: '/',
      list: []
    },
    {
      name: '工商信息',
      url: '/business',
      list: []
    },
    {
      name: '变更记录',
      url: '/change',
      list: []
    },
    {
      name: '法律诉讼',
      url: '',
      list: [
        {
          name: '被执行标的',
          url: '/law/executed'
        },
        {
          name: '裁决文书',
          url: '/law/ruling'
        },
        {
          name: '立案信息',
          url: '/law/case'
        },
        {
          name: '失信记录',
          url: '/law/promise'
        },
        {
          name: '限制高消费记录',
          url: '/law/high'
        },
        {
          name: '终本案件',
          url: '/law/final'
        },
        {
          name: '破产重整',
          url: '/law/bankruptcy'
        },
        {
          name: '股权冻结',
          url: '/law/stock'
        }
      ]
    },
    {
      name: '经营风险',
      url: '',
      list: [
        {
          name: '经营异常',
          url: '/risk/abnormal'
        },
        {
          name: '欠税公告',
          url: '/risk/notice'
        }
      ]
    },
    {
      name: '中标业绩',
      url: '/bid',
      list: []
    },
    {
      name: '新闻公告',
      url: '/news',
      list: []
    },
    {
      name: '黑名单',
      url: '/black',
      list: []
    },
    {
      name: '征信',
      url: '',
      list: []
    }
  ];

  getMenuActive () {
    let _active = '0'
    this.menu.forEach((item, index) => {
      if (item.url && this.$route.path.indexOf(item.url) !== -1) {
        _active = index.toString()
      }
      item.list.forEach((row, rowindex) => {
        if (row.url && this.$route.path.indexOf(row.url) !== -1) {
          _active = index + '-' + rowindex
        }
      })
    })
    return _active
  }

  ckUrl (url:string) {
    this.$router.push(url)
  }
}
